(function($) {


	function createXMLHttpRequest() {
	if (window.XMLHttpRequest) { // Firefox, Opera, Safari
	req = new XMLHttpRequest();
	} else if (window.ActiveXObject) { // Internet Explorer
	try {
	req = new ActiveXObject(Msxml2.XMLHTTP);
	} catch (e) {
	try {
	req = new ActiveXObject(Microsoft.XMLHTTP);
	} catch (e) {}
	}
	}
	return req;
	}
	function getReadyStateHandler(request, responseXmlHandler) {
	return function () {
	if (request.readyState == 4) {
	if (request.status == 200) {
	//document.body.style.cursor = default;
	responseXmlHandler(request.responseXML);
	} else {
	//document.body.style.cursor = default;
	//alert('ERROR: '+ request.status);
	}
	}
	}
	}

	function readCounts() {
	  var requestC = createXMLHttpRequest();
	  requestC.open('POST', '/wp-content/themes/myshornan/lib/cl_count.php',true);
	  requestC.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
	  var callbackHandlerC = getReadyStateHandler(requestC, displayCount);
	  requestC.onreadystatechange = callbackHandlerC;
	  sendstringC = "";
	  requestC.send(sendstringC);
	}
	function displayCount(returnXML) {
		var items = returnXML.getElementsByTagName('xmlresponse')[0];
		var numfemale = items.getElementsByTagName('female_count')[0].firstChild.data;
		var nummale = items.getElementsByTagName('male_count')[0].firstChild.data;

		// document.getElementById('cl_span').innerHTML = 'Antal tjejer:  '+numfemale+'<br>'+'Antal killar: '+nummale+'<br>';

		$('.num-girls').text( numfemale );
		$('.num-guys').text( nummale );

		// t=setTimeout(readCounts(),5000);
	}


	$(document).ready(function() {

		readCounts();

		window.setInterval(function(){
		  	readCounts();
		}, 5000);

	    // Elongate pages – make a page fill the height of the screen if the content isn’t long enough to fill the entire height of the page
	    windowHeight = $(window).outerHeight();
	    bodyHeight = $('body').outerHeight();
	    headerHeight = $('.banner').outerHeight();
	    footerHeight = $('.footer-container').outerHeight();


	    if(windowHeight > bodyHeight) {
	        heightToAdd = windowHeight - ( headerHeight + footerHeight );

	        //The content container that should be expanded
	        $('.wrap').css('min-height', heightToAdd);
	    }

	    var isIphone = /(iPhone)/i.test(navigator.userAgent);
		var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
	    var landscape = false;


	    if( isSafari && isIphone ) {
	    	$('body').addClass('safari');
	    } 

	    //check landscape
	    if( window.orientation === 90 || window.orientation === -90 ) {
	    	landscape = true;
	    	$('body').addClass('landscape');
	    }

	    $(window).on("orientationchange",function(event) {
	    	if( window.orientation === 90 || window.orientation === -90 ) {
		    	$('body').addClass('landscape');
		    	$('.simple-header-image-container').height( $(window).height() );
		    } else {
		    	$('body').removeClass('landscape');
		    }
	    });

	    var height = $(window).height();


	    if ( parseInt( $("#mq-medium").css("max-width"), 10 ) <= 768 && !landscape ) {

	    	$('.fancy .simple-header-image-container').height( (height/2) );
	    	$('.simple-header-image-container').height( (height/2) );

		    if( !$('.simple-header-container').hasClass('fancy') ) {
		    	$('.simple-header-text-container').height( height/2 );
		    }

	    }

	    // On init, iPhone 5 menu fix
		if ( parseInt( $("#mq-xsmall").css("max-width"), 10 ) <= 320 ) {
			$('.menu-hamburgare-container ul').height( height - 50 );
		}

		// Remove all p
		// $('p').each(function(index, item) {
		//     if($.trim($(item).text()) === "" && !$(item).children().length > 0) {
		//         $(item).slideUp(); // $(item).remove();
		//     }
		// });
		

		$('.slider-container').slick({
			arrows: false,
			autoplay: true,
			autoplaySpeed: 5000,
			pauseOnHover: false
		});

		// $.ajax({
		//   url: 'http://mh05.irial.net/ext_count.php',
		//   data: data,
		//   success: function( data ) {
		//   	console.log( data );
		//   }
		// });

		// $.get( "http://mh05.irial.net/ext_count.php", function( data ) {
		//   alert( "Data Loaded: " + data );
		// }, 'jsonp');


		// function logResults(json){
		//  console.log(json);
		// }

		// $.ajax({
		//  url: "http://mh05.irial.net/ext_count.php",
		//  dataType: "jsonp",
		//  jsonpCallback: "logResults"
		// });
		
	}); 


})(jQuery); // Fully reference jQuery after this point.
